<template>
	<div class="introduce">
		<div class="left">
			<div class="left-item" :class="{on: showIdx == index}" v-for="(item, index) in list" :key="this" @click="showIdx = index">
			<img :src="'https://admin.sungsavip.com/'+item.icon" alt="" style="width:23px;padding-right:10px">{{ item.title }}
			</div>
		</div>
		<div class="right">
			<h2>{{ list[showIdx].title }}</h2>
			<div class="right-con" v-html="list[showIdx].content"></div>
		</div>
	</div>
</template>

<script>
	import { introduce } from '@/api/cms/help'
	
	export default {
		name: 'introduce',
		data() {
			return {
				list: [],
				showIdx: 0
			};
		},
		created() {
			this.getIntroduce()
		},
		methods: {
			getIntroduce() {
				introduce({})
					.then(res => {
						if (res.code != 0) {
							throw new Error(res.message)
						}
						
						this.list = res.data.list
					})
					.catch(e => {
						this.$message(e.message || '加载失败')
					})
			}
		}
	};
</script>
<style lang="scss" scoped>
	.introduce {
		background-color: #ffffff;
		margin: 60px auto;
		min-height: 500px;
		display: flex;
		align-items: flex-start;
		
		.left {
			width: 220px;
			height: 100%;
			box-sizing: border-box;
			border-right: 1px solid #f4f4f4;
			padding: 20px;
			
			.left-item {
				width: 100%;
				height: 50px;
				font-size: 20px;
				font-weight: bold;
				color: $ns-text-color-black;
				// text-indent: 50px;
				text-align: center;
				position: relative;
				background-color: #f4f4f4;
				line-height: 50px;
				transition: all .3s ease;
				cursor: pointer;
				margin-bottom: 20px;
				
				&:hover, &.on {
					background-color: $base-color;
					color: #ffffff;
				}
				
			}
		}
		
		.right {
			padding: 20px;
			width: calc(100% - 220px);
			box-sizing: border-box;
			padding: 20px;
			
			h2 {
				font-size: 22px;
				font-weight: 400;
				color: ns-text-color-black;
				border-bottom: 1px solid #f4f4f4;
				padding-bottom: 5px;
			}
			
			.right-con {
				padding-top: 20px;
			}
		}
	}
</style>
